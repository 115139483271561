.mywork{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px 170px;
}
.mywork-title{
    position: relative;
}
.mywork-title h1{
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;
}
.mywork-title img{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.mywork-container{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;
}
.mywork-container img{
    box-sizing: border-box;
    width: 415px;
    height: 280px;
    transition: 0.3s;
    cursor: pointer;
}
.mywork-container img:hover{
    transform: scale(1.1);
    border: 4px solid #ff00ff;
    border-radius: 15px;
    transition: 0.4s;
}
.mywork-showmore{
    display: flex;
    gap: 15px;
    border-radius: 50px;
    border: 2px solid white;
    padding: 30px 60px;
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 80px;
    transition: 0.5s;
    cursor: pointer;
}
.mywork-showmore:hover{
    gap: 35px;
    transition: 0.5s;
}