.header{
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 35px;

}
.header img{
    width: 600px;
    height: 600px;
}
.header h1{
    text-align: center;
    width: 70%;
    font-size: 60px;
    font-weight: 600;
    font-weight: lighter;
}
.header h1 span{
    background: linear-gradient(270deg, #ff00e2 10%, #480EE8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.header p{
    width: 50%;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
}
.header-action{
    display: flex;
    text-align: center;
    gap: 25px;
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 50px;
}
.header-connect{
    padding: 25px 45px;
    border-radius: 50px;
    background: linear-gradient(264deg, #480EE8 -5.09%, #B415FF 106.28%, #ff00e2 50%);
    cursor: pointer;
}
.header-resume{
    padding: 25px 75px;
    border-radius: 50px;
    border: 2px solid #FFF;
    cursor: pointer;
}
.header-connect:hover{
    border: 2px solid white;
}
.header-resume:hover{
    border-color:#B415FF
}