.footer{
    margin: 50px 170px;
    display: flex;
    flex-direction: column;
    gap: 50px;
}
.footer-top{
    display: flex;
    justify-content: space-between;
}
.footer-top-left p{
    font-size: 20px;
    max-width: 400px;
}
.footer-top-left img{
    width: 150px;
    height: 150px;
    align-items: left;
}
.footer-top-right{
    display: flex;
    gap: 35px;
    align-items: center;
}
.footer-email-input{
    display: flex;
    gap: 30px;
    background: #32323b;
    border-radius: 50px;
    padding: 20px 30px;
    padding-right: 80px;
}
.footer-email-input input{
    background: transparent;
    outline: none;
    border: none;
    color: #a0a0a0;
    font-family: proxima-nova;
    font-size: 18px;
}
.footer-subscribe{
    font-size: 20px;
    padding: 22px 50px;
    border-radius: 50px;
    background: linear-gradient(264deg, #480EE8 0.36%, #B415FF 106.28%, #ff00e2 50%);
    cursor: pointer;
    transition: 0.3s;
}
.footer-subscribe:hover{
    transform: scale(1.05);
    transition: 0.4s;
}
.footer-bottom{
    display: flex;
    justify-content: space-between;
    font-size: 18px;
}
.footer-bottom-right{
    display: flex;
    gap: 50px;
    margin-bottom: 50px;
}
.footer-email-input:hover{
    border: 3px solid white;
    transition: 0.4s;
}