.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px 170px;
}
.contact-title{
    position: relative;
}
.contact-title h1{
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;
}
.contact-title img{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.contact-section{
    display: flex;
    gap: 150px;
}
.contact-left{
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.contact-left h1{
    font-size: 80px;
    font-weight: 700;
    background: linear-gradient(270deg, #ff00e2 29.5%, #480EE8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.contact-left p{
    max-width: 550px;
    color: #D8D8D8;
    font-size: 20px;
    line-height: 35px;
}
.contact-details{
    display: flex;
    flex-direction: column;
    gap: 30px;
    color: #D8D8D8;
    font-size: 22px;
}
.contact-detail{
    display: flex;
    align-items: center;
    gap: 20px;
}
.contact-right{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 30px;
}
.contact-right label{
    color: #D8D8D8;
    font-size: 22px;
    font-weight: 500;
}
.contact-right input{
    border: none;
    width: 700px;
    height: 78px;
    padding-left: 20px;
    border-radius: 4px;
    background: #32323c;
    color: #a0a0a0;
    font-family: proxima-nova;
    font-size: 20px;
}
.contact-right textarea{
    width: 650px;
    border: none;
    padding: 25px;
    border-radius: 4px;
    background: #32323c;
    color: #a0a0a0;
    font-family: proxima-nova;
    font-size: 20px;
}
.contact-button{ 
    border: none;
    color: white;
    border-radius: 50px;
    font-size: 22px;
    font-family: proxima-nova;
    background: linear-gradient(270deg, #480EE8 0.36%, #B415FF 106.28%, #ff00e2 50%);
    padding: 20px 60px;
    margin-bottom: 50px;
    cursor: pointer;
    transition: 0.3s;
}
.contact-button:hover{
    transform: scale(1.1);
    transition: 0.3s;
    border: 2px solid white;
}