.about{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
    margin: 80px 170px;
}
.about-title{
    position: relative;
}
.about-title h1{
    padding: 0px 30px;
    font-size: 80px;
    font-weight: 600;
}
.about-title img{
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: -1;
}
.about-sections{
    display: flex;
    gap: 80px;
}
.about-right{
    display: flex;
    flex-direction: column;
    gap: 80px;
}
.about-para{
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 24px;
    font-weight: 500;
}
.about-skills{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.about-skill{
    display: flex;
    gap: 50px; 
    align-items: center;
    transition: 0.3s;
}
.about-skill p{
    min-width: 150px;
    font-size: 24px;
    font-weight: 500;
}
.about-skill hr{
    outline: none;
    border: none;
    width: 50px;
    height: 8px;
    border-radius: 50px;
    background: linear-gradient(264deg, #480EE8 -5.09%, #B415FF 70.28%, #ff00e2 102.57%);
}
.about-achievements{
    display: flex;
    width: 100%;
    justify-content: space-around;
    margin-bottom: 80px;
}
.about-achievement{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    transition: 1s;
}
.about-achievement h1{
    font-size: 60px;
    font-weight: 700;
    background: linear-gradient(270deg, #ff00e2 2.2%, #480EE8 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.about-achievement p{
    font-size: 30px;
    font-weight: 500;
}
.about-skill:hover{
    transform: scale(1.07);
    transition: 0.3s;
}
.about-achievement:hover{
    transform: scale(1.2);
    transition: 0.3s;
}