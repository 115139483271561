.navbar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px 170px;
}
.Nav-Menu{
    display: flex;
    align-items: center;
    list-style: none;
    gap: 60px;
    font-size: 20px;
    transition: all 0.4s;
}
.Home{
    transition: transform 0.4s ease-in-out;
}
.About{
    transition: transform 0.4s ease-in-out;
}
.Services{
    transition: transform 0.4s ease-in-out;
}
.Portfolio{
    transition: transform 0.4s ease-in-out;
}
.Contact{
    transition: transform 0.4s ease-in-out;
}
.Home:hover{
    transform: scale(1.20);
}
.About:hover{
    transform: scale(1.20);
}
.Services:hover{
    transform: scale(1.20);
}
.Portfolio:hover{
    transform: scale(1.20);
}
.Contact:hover{
    transform: scale(1.20);
}
.image-container{
    width: 200px;
    height: 120px;
    padding-top: 50px;
    overflow: hidden;
}
.image-container img{
    width: auto;
    height: auto;
}
.nav-connect{
    padding: 20px 40px;
    border-radius: 50px;
    background: linear-gradient(264deg, #480EE8 -5.09%, #B415FF 106.28%, #ff00e2 50%);
    font-size: 22px;
    cursor: pointer;
    transition: 0.3s;
}
.nav-connect:hover{
    transform: scale(1.05);
}
.nav-menu li{
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}
.nav-menu li img{
    display: flex;
    margin: auto;
}
.anchor-link{
    text-decoration: none;
    color: white;
}